import React, { useEffect, useMemo, useState } from 'react';
import { DropDownButton } from '@GDM/Button/DropDownButton';
import { styles } from '@GDM/Filters';
import { Checkbox, sectionStyles } from '@GDM/forms';
import { HelpBox } from '@GDM/HelpBox/HelpBox';
import { Toggle } from '@GDM/Toggle';
import { contractTypeList } from '@utils/constants/contractTypesList';
import { ContractType } from '@utils/types/contract';
import dayjs from 'dayjs';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { DateInput } from '../../components/Inputs/Date';
import { ListInput } from '../../components/Inputs/List';
import { SectionHeader } from '../../components/SectionHeader';
import { ConfigurableFields } from './components/ConfigurableFields';
import { MainContractInfo } from './components/MainContractInfo';
import contractTypePickerStyles from './styles/contract-type-picker.module.scss';

export const GeneralInfo = ({
  title,
  readOnly,
  globals: { offtaker, formMode, installation },
  queries,
  form: { control, watch, setValue, trigger },
}: ContractFormSectionProps) => {
  const installationCountry = installation?.country || 'FR';
  const [contractType, invoicingPlatform] = watch(['type', 'invoicing_platform']);

  const isGLSFInstallation =
    typeof installation?.has_meter === 'boolean' && installation.status !== 'draft' ? !installation.has_meter : false;

  const [contractTypeChangeEnabled, setContractTypeChangeEnabled] = useState<boolean | undefined>(formMode !== 'edit');

  const [configurableFields, setConfigurableFields] = useState<Set<ConfigurableField>>(() => {
    const configurableFieldsFromLocalStorage = localStorage.getItem('configurableFields');
    const parsedConfigurableFieldsFromLocalStorage = configurableFieldsFromLocalStorage?.split(',');

    return parsedConfigurableFieldsFromLocalStorage
      ? new Set(parsedConfigurableFieldsFromLocalStorage as ConfigurableField[])
      : new Set();
  });

  useEffect(() => {
    if (contractType === 'ContractSwapPl') setValue('currency', 'PLN');
    if (contractType === 'ContractSwapUk') setValue('currency', 'GBP');
  }, [setValue, contractType]);

  useEffect(() => {
    if (invoicingPlatform === 'cegedim') trigger('contract_nb');
  }, [invoicingPlatform, trigger]);

  const defaultStartDate = useMemo(() => new Date(), []);
  const defaultEndDate = dayjs(defaultStartDate).add(1, 'year').toDate();

  return (
    <FormSectionLayout
      title={title}
      headerActions={
        <SectionHeader>
          <DropDownButton
            tooltip="sales_management.configurable_fields.tooltip"
            className="ml-2"
            icon="Settings"
            variant="primary-2"
            position="right"
            data-cy="configurable-fields-dropdown"
            noChevron
            floating
          >
            <div className="p-2 d-flex flex-column gap-1" data-cy="configurable-fields-list">
              {CONFIGURABLE_FIELDS.map((field) => (
                <Checkbox
                  label={`sales_management.configurable_fields.${field}`}
                  checked={configurableFields.has(field)}
                  onChange={() => {
                    setConfigurableFields((prev) => {
                      const next = new Set(prev);

                      if (prev.has(field)) {
                        next.delete(field);
                      } else {
                        next.add(field);
                      }

                      return next;
                    });
                  }}
                  key={field}
                  name={`configurable-field-${field}`}
                />
              ))}
            </div>
          </DropDownButton>
        </SectionHeader>
      }
      body={
        <div className={sectionStyles.container} data-cy="general-information">
          <div className="p-3">
            <MainContractInfo
              control={control}
              invoicingPlatform={invoicingPlatform}
              readOnly={readOnly}
              queries={queries}
              installation={installation}
              contractType={contractType}
              offtaker={offtaker}
            />

            <div className={sectionStyles.row}>
              <DateInput
                id="StartDatePicker"
                name="start_date"
                control={control}
                rules={{ required: true }}
                label="common.start_date"
                readOnly={readOnly}
                data-cy="contract-start-date"
                defaultValue={defaultStartDate}
              />
              <DateInput
                id="EndDatePicker"
                name="end_date"
                control={control}
                rules={{ required: true }}
                label="common.end_date"
                readOnly={readOnly}
                data-cy="contract-end-date"
                defaultValue={defaultEndDate}
              />
            </div>

            <ConfigurableFields
              control={control}
              readOnly={readOnly}
              installation={installation}
              configurableFields={configurableFields}
            />
          </div>

          <div className={sectionStyles['section-footer']}>
            <div className="pt-3 pr-3 pl-3">
              <div className={contractTypePickerStyles.wrapper}>
                {formMode === 'edit' && (
                  <Toggle
                    value={contractTypeChangeEnabled}
                    onChange={setContractTypeChangeEnabled}
                    tooltip="sales_management.info.contract_type_change"
                    className={styles.toggle}
                  />
                )}
                <ListInput
                  className={contractTypePickerStyles.select}
                  id="ContractTypePicker"
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  label="sales_management.contract_type"
                  options={contractTypeList[isGLSFInstallation ? 'GLSF' : installationCountry] || []}
                  isDisabled={!installation || !contractTypeChangeEnabled}
                  readOnly={readOnly}
                  classNamePrefix="select-contract-type"
                  menuPlacement="top"
                  isSearchable
                  helpBox={{ title: 'sales_management.contract_type', text: 'contracts.info.contract_type' }}
                  defaultValue={null}
                />
              </div>
              {!readOnly && contractType && CONTRACT_TYPE_HELP_BOXES[contractType] && (
                <HelpBox text={CONTRACT_TYPE_HELP_BOXES[contractType]} />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const CONFIGURABLE_FIELDS = [
  'erp_code',
  'card_i',
  '_non_contract_props.installation.owner_erp_code',
  '_non_contract_props.installation.external_ref',
  'p_max',
  'signature_date',
] as const;

export type ConfigurableField = typeof CONFIGURABLE_FIELDS[number];

const CONTRACT_TYPE_HELP_BOXES: Partial<Record<ContractType, string>> = {
  ContractOa: 'contracts.info.oa',
  ContractCr: 'contracts.info.cr',
  ContractCrEdf: 'contracts.info.cr_edf',
  ContractSwap: 'contracts.info.swap',
};
